<template>
  <v-container class="bg-section mb-16 py-16" fluid>
    <v-row>
      <v-col cols="12" :md="4" class="hidden-sm-and-down">
        <v-img
          :src="require(`@/assets/facturacion/woman-working.png`)"
          height="360px"
          class="ml-16"
          contain
        ></v-img>
      </v-col>
      <v-col cols="11" :md="4" class="text-center mx-xs-12 mx-sm-12 mx-md-0">
        <h6>CONSULTA TODA LA INFORMACIÓN</h6>
        <h2 class="mb-6">Revisa fácilmente tu facturación</h2>
        <p class="">
          Acist te proporciona información en tiempo real de tu facturación:
          total facturado, evolución de ventas anual, volumen pendiente de pago,
          etc. No pierdas de vista el crecimiento de tu negocio.
        </p>
      </v-col>
      <v-col cols="12" :md="4" class="">
        <v-img
          :src="require(`@/assets/facturacion/statistics.png`)"
          height="360px"
          class="mr-16"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EasyProcess",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
