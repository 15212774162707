<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :sm="6" class="cont-img hidden-sm-and-down">
        <img
          :src="require('@/assets/facturacion/db-clients.png')"
          contain
          height="380"
          class="mr-12"
        />
      </v-col>
      <v-col cols="10" :md="6" class="mt-4">
        <div class="mr-lg-16 mb-14">
          <h6 class="mr-md-6 pr-md-6 mr-lg-16 pr-lg-16">CONSULTAS DE CADA CLIENTE</h6>
          <h2 class="b-6 mr-md-6 pr-md-6 mr-lg-16 pr-lg-16">
            Gestiona tu base de datos de clientes
          </h2>
          <p class="mr-md-6 pr-md-6 mr-lg-16 pr-lg-16">
            Dales de alta dentro de Acist con toda su información fiscal y crea
            su propia ficha de contacto. Podrás consultar el histórico de cada
            uno de ellos, ver de un vistazo el valor de las facturas pendientes
            y acceder directamente a los documentos relacionados (facturas,
            albaranes, presupuestos, etc.)
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CheckDB",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
    text-align-last: right;
}
</style>
