<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="5" :lg="6" class="mt-4">
        <div class="ml-lg-16 mb-14 mx-8 mx-sm-10">
          <h6 class="ml-lg-16 pl-lg-16">ESTADO EN TIEMPO REAL DE TUS FACTURAS</h6>
          <h2 class="b-6 ml-lg-16 pl-lg-16">
            Recibe notificaciones del estado en tiempo real de tus facturas
            enviadas
          </h2>
          <p class="ml-lg-16 pl-lg-16">
            Recibe notificaciones cuando tus clientes hayan recibido o
            descargado la factura. También, consulta toda la información desde
            tu propio teléfono móvil, estés donde estés.
          </p>
        </div>
      </v-col>
      <v-col cols="12" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/facturacion/xml-img.png')"
          contain
          height="380"
          class="ml-lg-12"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Notification",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
@media (max-width: 960px) {
  .cont-img {
    text-align-last: center;
  }
}
</style>
