<template>
  <v-container class="mb-16" fluid>
    <v-row class="mb-10 justify-center">
      <v-col cols="10" class="bg-card">
        <div class="mx-md-12 mb-4">
          <v-row>
            <v-col :md="3">
              <img
                :src="require('@/assets/facturacion/list-clients.png')"
                contain
                height="160"
                class="img-cta-l hidden-md-and-down"
              />
            </v-col>
            <v-col cols="10" :md="6">
              <h2 class="pb-2 mt-14 white--text text-center">
                Saca el máximo provecho a tu facturación
              </h2>
            </v-col>
            <v-col :md="3">
              <img
                :src="require('@/assets/facturacion/woman-book.png')"
                contain
                height="200"
                class="img-cta-r hidden-md-and-down"
              />
            </v-col>
          </v-row>
          <v-row class="mt-14 mb-10">
            <v-col cols="12" :md="6">
              <v-list-item-group color="primary" class="mx-2 mx-md-6">
                <v-list-item v-for="(item, i) in points" :key="i" class="mb-4">
                  <v-list-item-icon class="mr-3">
                    <div color="white">
                      <v-img
                        :src="require('@/assets/icons/point-w.svg')"
                        contain
                        height="20"
                      />
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content style="display: inline">
                    <h5 class="white--text">{{ item.title }}</h5>
                    <p class="white--text font-weight-light">
                      {{ item.subtitle }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
            <v-col cols="12" :md="6">
              <v-list-item-group color="primary" class="mx-2 mx-md-6">
                <v-list-item v-for="(item, i) in points2" :key="i" class="mb-4">
                  <v-list-item-icon class="mr-3">
                    <div color="white">
                      <v-img
                        :src="require('@/assets/icons/point-w.svg')"
                        contain
                        height="20"
                      />
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content style="display: inline">
                    <h5 class="white--text">{{ item.title }}</h5>
                    <p class="white--text font-weight-light">
                      {{ item.subtitle }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
          </v-row>
          <v-row class="mb-12">
            <v-col :md="3">
              <img
                :src="require('@/assets/facturacion/man-chat.png')"
                contain
                height="200"
                class="img-cta-l hidden-md-and-down"
              />
            </v-col>
            <v-col :md="6">
              <v-btn
                text
                block
                x-large
                class="btn-cta white--text mt-12 pr-16 mr-16"
              >
                Solicita una demo
              </v-btn>
            </v-col>
            <v-col :md="3">
              <img
                :src="require('@/assets/facturacion/descr-fac.png')"
                contain
                height="360"
                class="img-cta-rb hidden-md-and-down"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CTAPlus",

  components: {},
  data: () => ({
    points: [
      {
        title: "Datos autocompletados",
        subtitle:
          "Acist introduce por ti los datos fiscales de tus clientes con un 100% de fiabilidad.",
      },
      {
        title: "Presupuestos",
        subtitle:
          "Envía tus presupuestos a través de Acist y conviértelos en facturas en un clic.",
      },
      {
        title: "Facturas proforma",
        subtitle:
          "Crea tus facturas proforma personalizadas y envíalas directamente desde Acist.",
      },
    ],
    points2: [
      {
        title: "Gastos suplidos",
        subtitle:
          "Deduce tus gastos suplidos fácilmente creando las facturas desde Acist.",
      },
      {
        title: "Facturas rectificativas",
        subtitle:
          "Crea el modelo de factura correcto para rectificar los datos de otra.",
      },
      {
        title: "App móvil",
        subtitle:
          "Crea y envía tus facturas estés donde estés desde tu Smartphone o tablet.",
      },
    ],
  }),
};
</script>

<style scoped>
p {
  line-height: 1.6;
}
.bg-card {
  background: #141e56;
  border-radius: 20px;
}
.icon-banner {
  background-position: left;
}
.img-cta-r {
  position: absolute;
  margin-left: 10% !important;
  margin-top: 30px;
}
.img-cta-rb {
  position: absolute;
  margin-left: 7% !important;
}
.img-cta-l {
  position: absolute;
  margin-left: -10% !important;
  margin-top: 30px;
}
.btn-cta {
    background: #5A88FE;
    box-shadow: 0px 1px 18px rgba(255, 255, 255, 0.5);
    border-radius: 40px;
}
</style>
