<template>
  <v-container class="pa-0" fluid>
    <BannerFacturacion />
    <EasyProcess />
    <CheckDB />
    <Notification />
    <CTAPlus />
    <AllYouNeed />
  </v-container>
</template>

<script>
  import BannerFacturacion from '../components/sections/BannerFacturacion.vue'
  import EasyProcess from '../components/sections/EasyProcess.vue'
  import CheckDB from '../components/sections/CheckDB.vue'
  import Notification from '../components/sections/Notification.vue'
  import CTAPlus from '../components/sections/CTAPlus.vue'
  import AllYouNeed from '../components/sections/AllYouNeed.vue'

  export default {
    name: 'Facturacion',

    components: {
      BannerFacturacion,
      EasyProcess,
      CheckDB,
      Notification,
      CTAPlus,
      AllYouNeed
    },
  }
</script>