<template>
  <v-container class="mb-16" fluid>
      <v-row class="mt-4 mt-md-16">
          <v-col cols="12" :md="5" class="ml-md-14">
              <div class="mb-8 mx-xs-8 mx-sm-10 ml-lg-16 mt-4 mt-md-16 text-center justify-center align-center">
                  <h1 class="mb-6">Simplifica tu facturación</h1>
                  <p class="pb-8 mt-8">Con Acist podrás crear tus facturas de forma rápida y sencilla, enviarlas y hacer un seguimiento de su estado.</p>
              </div>
              <v-row class="justify-center align-center mb-xs-10 mb-sm-8">
                <div class="text-center mx-lg-12">
                <v-btn
                    rounded
                    x-large
                    class="mainButton"
                    dark
                >
                    Probar Gratis
                </v-btn>
                </div>
                <div class="text-center ml-10">
                <v-btn
                    rounded
                    x-large
                    color="primaryLint"
                    class="red--text"
                    dark
                >
                    Contactarme 
                </v-btn>
                </div>
            </v-row>
          </v-col>
          <v-col cols="12" :md="6">
              <div>
                  <v-img
                    :src="require('@/assets/facturacion/facturacion.png')"
                    class=""
                    contain
                    height="400"
                    />
              </div>
          </v-col>
      </v-row>
      <v-row class="mb-10 mt-n2 justify-center">
        <v-col cols="10" class="bg-card">
          <div class="mx-3 mx-md-12 mb-4">
            <v-row>
                <v-col cols="10" :md="4">
                    <h2 class="pb-2 mt-14 white--text">Realiza facturas perfectas de forma sencilla y en segundos</h2>
                </v-col>
                <v-col :md="1">
                    
                </v-col>
                <v-col cols="12" :md="7">
                    <v-row>
                        <v-col cols="12" :md="4" class=" inline-with-text">
                            <img
                            :src="require('@/assets/icons/icon-f1.png')"
                            contain
                            height="44"
                            class="mt-10 icon-banner mx-2"
                            />
                            <p class="pb-2 mt-8 mx-2 white--text">Cuentas con una interfaz intuitiva para el llenado de datos de tu factura.</p>
                        </v-col>
                        <v-col cols="12" :md="4" class=" inline-with-text">
                            <img
                            :src="require('@/assets/icons/icon-f2.png')"
                            contain
                            height="44"
                            class="mt-10 icon-banner mx-2"
                            />
                            <p class="pb-2 mt-8 mx-2 white--text">El editor de facturas te ayuda a completar la información de tu factura sin errores.</p>
                        </v-col>
                        <v-col cols="12" :md="4" class=" inline-with-text">
                            <img
                            :src="require('@/assets/icons/icon-f3.png')"
                            contain
                            height="44"
                            class="mt-10 icon-banner mx-2"
                            />
                            <p class="pb-2 mt-8 mx-2 white--text">Comprueba de un vistazo tus facturas pagadas y pendientes de cobro.</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'BannerFacturacion',

    components: {
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
.bg-card {
  background: #141E56;
    border-radius: 20px;
}
.icon-banner {
    background-position: left;
}

@media (max-width: 959px) {
  .inline-with-text {
    display: flex;
}
}
</style>
